import React from 'react';

import './App.css';

import ShopImage from './assets/img_shop.png';
import BoxImage from './assets/img_box.png';
import Web3jsIcon from './assets/icon_web3js.jpg';
import BrowsersIcon from './assets/icon_browsers.png';
import IosIcon from './assets/icon_ios.jpg';
import AndroidIcon from './assets/icon_android.png';

const APP_NAME = 'CUBE';
const CONTACT_US_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSeaya4B0G7bTgpIenOsZadarQfFmTIGeyTNdNfbeihp2vAOxw/viewform?usp=sf_link';

const plans = [
  {
    name: 'Free',
    price: 0,
    users: 2500,
    features: [
      'UI Widget',
      '支援 Web3.js',
      '兩步驟驗證',
      '最多 100,000 請求/天',
      '社群支援',
    ],
  },
  {
    name: 'Startup',
    price: 130,
    users: 5000,
    features: [
      '所有 Free 方案功能',
      '最多 500,000 請求/天',
      'Email 客服支援',
    ],
  },
  {
    name: 'Growth',
    price: 250,
    users: 10000,
    features: [
      '所有 Startup 方案功能',
      '最多 1,500,000 請求/天',
    ],
  },
  {
    name: 'Enterprise',
    features: [
      '所有 Growth 方案功能',
      '最多 5,000,000 請求/天',
      '電話客服支援',
    ],
  },
];

const ContactButton = React.memo(props => {
  const { white } = props;
  return (
    <div className={`contact ${white ? 'white' : ''}`}>
      <a className="contact-button" href={CONTACT_US_URL} target="_blank" rel="noreferrer noopener">聯絡我們</a>
    </div>
  );
});

const Plan = React.memo(props => {
  const { name, price, users, features } = props;
  return (
    <div className="plan">
      <div className="plan-name">{name}</div>
      <div className="plan-price">{price !== undefined ? <>${price}<span className="unit"> /月</span></> : <span>聯絡我們</span>}</div>
      <div className="plan-users">{users ? `最多 ${users.toLocaleString()} 名活躍用戶` : '無活躍用戶上限'}</div>
      <div className="plan-features">
        {features.map(feature => (
          <div>
            <svg width="24" height="18"><path d="M8.5 17.3a1.5 1.5 0 0 1-1.061-.438L.379 9.8 2.5 7.68l6 6 13-13 2.121 2.12-14.06 14.062A1.5 1.5 0 0 1 8.5 17.3z" fill="#20CD63"></path></svg>
            <div>{feature}</div>
          </div>
        ))}
      </div>
    </div>
  )
});

function App() {
  return (
    <div className="App">
      <header>
        <div>
          <div className="logo">{APP_NAME}</div>
          <div>
            <a href="#feature-1">功能</a>
            <a href="#developer">開發者</a>
            <a href="#pricing">價格</a>
            <a href={CONTACT_US_URL} target="_blank" rel="noreferrer noopener">聯絡我們</a>
          </div>
        </div>
      </header>
      <article>
        <section id="feature-1">
          <div className="content col">
            <div className="topic">
              <div className="title">購買與管理數位收藏品</div>
              <div className="desc">多種支付方式讓您盡情蒐集 App、音樂與各式出版品，並使用專業的加密技術來管理</div>
              <ContactButton />
            </div>
            <div className="image-wrapper shop">
              <img height={480} src={ShopImage} alt="shop" />
            </div>
          </div>
        </section>
        <section id="feature-2">
          <div className="content col">
            <div className="image-wrapper box">
              <img height={400} src={BoxImage} alt="box" />
            </div>
            <div className="topic">
              <div className="title">保管回憶、照片等珍貴資料</div>
              <div className="desc">無須安裝任何軟體，您的收藏便能藉由安全加密技術妥善保存，不用擔心遺失或受損</div>
              <ContactButton white />
            </div>
          </div>
        </section>
        <section id="developer">
          <div className="content row">
            <div className="topic">
              <div className="title">輕鬆設定</div>
              <div className="desc">提供 Web UI Widget 和符合 web3.js 規格的 SDK<br />還有瀏覽器套件以及 iOS / Android 雙平台 App</div>
            </div>
            <div className="platforms">
              <div><img height={56} src={Web3jsIcon} alt="web3.js" /></div>
              <div><img height={56} src={BrowsersIcon} alt="browsers" /></div>
              <div><img height={42} src={IosIcon} alt="ios" /></div>
              <div><img height={42} src={AndroidIcon} alt="android" /></div>
            </div>
            <ContactButton />
          </div>
        </section>
        <section id="pricing">
          <div className="content row">
            <div className="topic">
              <div className="title">免費註冊使用</div>
              <div className="desc">多種彈性方案，依您的需求選擇</div>
            </div>
            <div>
              <div className="card">
                {plans.map(plan => <Plan {...plan} />)}
              </div>
            </div>
            <ContactButton />
          </div>
        </section>
      </article>
      <footer>
        <div>
          <div className="info">
            <div className="logo">{APP_NAME}</div>
            <div>© 2020 恆翠有限公司</div>
            <div><a href="mailto:hello@sempervirensltd.com">hello@sempervirensltd.com</a></div>
            <div>02-27221325</div>
          </div>
          <div className="more">
            <div className="list">
              <div>關於</div>
              <a href={CONTACT_US_URL} target="_blank" rel="noreferrer noopener">聯絡我們</a>
            </div>
            <div className="list">
              <div>資源</div>
              <a href="#feature-1">功能</a>
              <a href="#developer">開發者</a>
              <a href="#pricing">價格</a>
            </div>
            <div className="list">
              <div>SDKs</div>
              <a>JavaScript</a>
              <a>React Native</a>
            </div>
            <div className="list">
              <div>下載</div>
              <a>iOS</a>
              <a>Android</a>
              <a>Mac</a>
              <a>Windows</a>
              <a>Chrome Extension</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
